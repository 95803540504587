<template>
    <w-data-table
        hide-default-header
        :headers="headers"
        :items="items"
        item-key="uuid"
        hide-default-footer
        searchable
        selectable-key="uuid"
        :filter-by="['title']"
        @click:row="item => $emit('click:row', item)"
    >
        <template #actions>
            <v-btn
                icon
                @click="fetchStatistic"
            >
                <w-icon
                    color="warning"
                    value="REFRESH"
                />
            </v-btn>
        </template>

        <template #item.title="{item}">
            <div class="py-2">
                <div>{{ item.title }}</div>
                <div v-if="isMobile">
                    <v-divider class="my-1"/>
                    <questions-status-label
                        v-for="(value, status) in item.data"
                        :key="status"
                        :status="status"
                        :value="value"
                        label
                        class="mt-1"
                    />
                </div>
            </div>
        </template>

        <template
            v-for="status in statusKeys"
            #[`item.${status}`]="{item}"
        >
            <questions-status-label
                :key="status"
                :status="status"
                :value="item.data[status]"
                small
                label
            />
        </template>

        <template #item.actions>
            <w-icon-next
                color="primary"
            />
        </template>
    </w-data-table>
</template>

<script>
import { getQuestionsStatistic } from '@apps/questions/api'
import QuestionsStatusLabel from '@apps/questions/components/QuestionsStatusLabel'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'QuestionsStatistic',
    mixins: [mobileBreakpointChecker],
    components: { QuestionsStatusLabel },
    props: {
        sharedType: {
            type: String,
            default: null
        },
        sharedUuid: {
            type: String,
            default: null
        },
        passedType: {
            type: String,
            default: null
        },
        authorUuid: {
            type: String,
            default: null
        }
    },
    computed: {
        statusKeys() {
            return [
                'passed',
                'not_passed',
                'broken',
                'pending',
            ]
        },
        headers() {
            const headers = [
                {
                    text: this.$trans('Test'),
                    value: 'title'
                }
            ]

            this.statusKeys.forEach(status => {
                headers.push({
                    value: status,
                    sortable: false,
                    align: 'right'
                })
            })

            headers.push({
                value: 'actions',
                sortable: false,
                align: 'right'
            })

            return headers
        }
    },
    data() {
        return {
            loading: false,
            items: []
        }
    },
    async mounted() {
        await this.fetchStatistic()
    },
    methods: {
        async fetchStatistic() {
            this.$lprogress.begin()

            try {
                const params = {}

                if(this.sharedType) params.shared_type = this.sharedType
                if(this.sharedUuid) params.shared_uuid = this.sharedUuid
                if(this.authorUuid) params.author_uuid = this.authorUuid

                const response = await getQuestionsStatistic(params)

                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
