<template>
    <questions-statistic
        shared-type="group"
        :shared-uuid="uuid"
        :author-uuid="teacherUuid"
        @click:row="onRowClick"
    />
</template>

<script>
import QuestionsStatistic from '@apps/questions/components/QuestionsStatistic'

export default {
    name: 'GroupQuestionsStatistic',
    components: { QuestionsStatistic },
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    computed: {
        teacherUuid() {
            return this.$auth.user.uuid
        }
    },
    methods: {
        onRowClick(item) {
            this.$router.push({
                name: 'groups.questions.results',
                params: {
                    uuid: this.uuid,
                    quuid: item.questions_uuid
                }
            })
        }
    }
}
</script>

<style lang=scss>

</style>
